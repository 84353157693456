import React from "react"
import axios from "axios"
import { useEffect, useState } from "react"
import "../index.scss"

interface Item {
    link:string
    title:string
    description:string
    summary:string
    published:string
    image:string
    author:string
}

export const App = () => {
    const [data, setData] = useState([])
    useEffect(() => {
        axios.get("https://vestinp.deta.dev").then((res) => {
            setData(res.data)
        })
    }, [])
    

    return <div className="wrapper">
        {data.length > 0 && data.map((item: Item) => {
            return <div className="card" key={item.link}>
                <h1>{item.title}</h1>
                <img src={item.image} alt={item.title} />
                <p>{item.summary}</p>
                <div className="card-details">
                    <h5>{item.author}</h5>
                    <h5>{item.published}</h5>
                </div>
            </div>
        })}
    </div>
}